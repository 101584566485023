import * as React from "react";
import Amplify, { API, graphqlOperation }  from "aws-amplify";

import awsmobile from "../aws-exports";
import { getParty } from "../graphql/queries";

Amplify.configure(awsmobile);

const useParty = (props) => {
  const id = props.location.pathname.replace('/party/', '');
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      const res = await API.graphql(graphqlOperation(getParty, {
        id: id
      }));
      setData(res.data.getParty);
    })()
  }, [id])
  return { data }
}

export default useParty
