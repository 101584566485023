import * as React from "react"
import { format } from 'date-fns'

import useParty from "@hooks/useParty"
import { partyImageUrl } from "@enums/index"

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import JsonLd from "@components/common/JsonLd"
import Loading from "@components/common/Loading"
import Breadcrumbs from "@components/party/Breadcrumbs"
import Main from "@components/party/Main"
import Service from "@components/party/Service"

const Party = (props) => {
  const { data } = useParty(props);
  if(!data) return <Loading />
  return (
    <Layout>
      <Seo
        title={`既婚者合コン - ${data.title} | Cuddle Place`}
        description={`${data.area} - ${format(new Date(data.date), 'yyyy年M月d日')}開催「${data.title}」。婚者向け総合メディアCuddle Place（カドル プレイス）では既婚者向け合コンや今の既婚者の出会いのリアルを追うことができます。`}
        image={`https://place.cuddle-jp.com${partyImageUrl[data.id.split('_')[0]]}`}
      />
      <JsonLd
        type='event'
        title={`既婚者合コン - ${data.title} | Cuddle Place`}
        description={`${data.area} - ${format(new Date(data.date), 'yyyy年M月d日')}開催「${data.title}」。婚者向け総合メディアCuddle Place（カドル プレイス）では既婚者向け合コンや今の既婚者の出会いのリアルを追うことができます。`}
        detail={{
          ...data,
          image: `https://place.cuddle-jp.com${partyImageUrl[data.id.split('_')[0]]}`
        }}
      />
      <Breadcrumbs title={data.title} />
      <Main {...data} />
      <Service />
    </Layout>
  )
}

export default Party
