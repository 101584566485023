import * as React from "react"
import styled from "styled-components"
import { navigate, Link } from "gatsby"
import { format } from 'date-fns'
import ja from "date-fns/locale/ja"

import { serviceNameUrl } from "@enums/index"

import PartyImage from "@components/common/PartyImage"
import Button from "@components/common/Button"

const Main = props => {
  const {
    id,
    access,
    address,
    area,
    date,
    endAt,
    femaleAgeEnd,
    femaleAgeFrom,
    femaleFeeMax,
    femaleFeeMin,
    femaleVacancy,
    location,
    maleAgeEnd,
    maleAgeFrom,
    maleFeeMax,
    maleFeeMin,
    maleVacancy,
    serviceName,
    startAt,
    title,
    url
  } = props;
  return (
    <Container {...props}>
      <ImageWrapper>
        <PartyImage
          src={`${id.split('_')[0]}`}
          ImageStyle={ImageStyle}
          alt='Cuddle Place（カドル プレイス）の既婚者合コン・サークル'
        />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Info>
          <SubTitle>合コン・パーティ概要</SubTitle>
          <Section>
            <Label>開催日時</Label>
            <Text>{`${format(new Date(date), 'M/d(E)', { locale: ja })} ${startAt.split(':')[0]}:${startAt.split(':')[1]}〜${endAt.split(':')[0]}:${endAt.split(':')[1]}`}</Text>
          </Section>
          <Section>
            <Label>エリア</Label>
            <Text>{area}{access && ` | ${access}`}</Text>
          </Section>
          {address &&
            <Section>
              <Label>住所</Label>
              <Text>{address}</Text>
            </Section>
          }
          {location &&
            <Section>
              <Label>会場</Label>
              <Text>{location}</Text>
            </Section>
          }
          <Section className="column-content">
            <Label>主催者</Label>
            <Text><Link to={serviceNameUrl[serviceName]} target="_blank">{serviceName}</Link></Text>
          </Section>
        </Info>
        <Detail>
          <DetailLabel>参加費・予約状況</DetailLabel>
          <DetailSection>
            <DetailSectionLabel sex="male">
              <Sex sex="male">男性</Sex>
            </DetailSectionLabel>
            <DetailSectionContent>
              <DetailSectionContentSection>
                <DetailSectionContentLabel>予約状況</DetailSectionContentLabel>
                <DetailSectionContentText>{maleVacancy? '空席あり':'満員'}</DetailSectionContentText>
              </DetailSectionContentSection>
              <DetailSectionContentSection>
                <DetailSectionContentLabel>対象年齢</DetailSectionContentLabel>
                <DetailSectionContentText>{`${maleAgeFrom}〜${maleAgeEnd}歳`}</DetailSectionContentText>
              </DetailSectionContentSection>
              <DetailSectionContentSection>
                <DetailSectionContentLabel>参加費</DetailSectionContentLabel>
                <DetailSectionContentText>{`${Number(maleFeeMin).toLocaleString()}〜${Number(maleFeeMax).toLocaleString()}円`}</DetailSectionContentText>
              </DetailSectionContentSection>
            </DetailSectionContent>
          </DetailSection>
          <DetailSection>
            <DetailSectionLabel sex="female">
              <Sex sex="female">女性</Sex>
            </DetailSectionLabel>
            <DetailSectionContent>
              <DetailSectionContentSection>
                <DetailSectionContentLabel>予約状況</DetailSectionContentLabel>
                <DetailSectionContentText>{femaleVacancy? '空席あり':'満員'}</DetailSectionContentText>
              </DetailSectionContentSection>
              <DetailSectionContentSection>
                <DetailSectionContentLabel>対象年齢</DetailSectionContentLabel>
                <DetailSectionContentText>{`${femaleAgeFrom}〜${femaleAgeEnd}歳`}</DetailSectionContentText>
              </DetailSectionContentSection>
              <DetailSectionContentSection>
                <DetailSectionContentLabel>参加費</DetailSectionContentLabel>
                <DetailSectionContentText>{`${Number(femaleFeeMin).toLocaleString()}〜${Number(femaleFeeMax).toLocaleString()}円`}</DetailSectionContentText>
              </DetailSectionContentSection>
            </DetailSectionContent>
          </DetailSection>
        </Detail>
        <Action>
          <Button
            variant='contained'
            size='meduium'
            font='meduium'
            width='240px'
            sub="（外部サイト）"
            onClick={() => navigate(url)}
          >
            申し込む
          </Button>
        </Action>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const ImageStyle = {
  width: '100%'
}

const Content = styled.div`
  padding: 28px 24px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
`;

const SubTitle = styled.h2`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 15px;
`;

const Info = styled.div`
  margin-top: 36px;
  margin-bottom: 64px;
`;

const Section = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Label = styled.span`
  width: 25%;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #666666;
`;

const Text = styled.span`
  width: 75%;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #333333;
`;

const Detail = styled.div`
  margin-bottom: 64px;
`;

const DetailLabel = styled.h3`
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #333333;
`;

const DetailSection = styled.div`
  margin-top: 30px;
  display: flex;
`;

const DetailSectionLabel = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border: ${props => props.sex === 'male'? '1px solid #7CC2C4':'1px solid #F39381'};
  padding: 0px 10px;
  margin-right: 16px;
`;

const Sex = styled.span`
  color: ${props => props.sex === 'male'? '#7CC2C4':'#F39381'};
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1em;
`;

const DetailSectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DetailSectionContentSection = styled.div`
  display: flex;
  margin: 7.5px 0px;
`;

const DetailSectionContentLabel = styled.h4`
  width: 30%;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #666666;
`;

const DetailSectionContentText = styled.span`
  width: 70%;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #333333;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Main
